import { computed, ref } from 'vue';
import { useStore } from '@/store/index';
import { getStringForKey, getDealStringForKey, getLocalisedLinks, linkType } from "@/translations/utilities";
import { generateBranchLoginLink } from "../helpers/utilities";
import isEmail from "validator/es/lib/isEmail";

export default function useAccountStore() {
  const store = useStore();
  const gettingDeal = ref(false);
  
  // Computed properties for accessing Vuex state and getters
  const account = computed(() => store.state.account);
  const offering = computed(() => store.getters['account/offering']);
  const originalLink = computed(() => store.getters['account/originalLink']);
  const deal = computed(() => store.getters['account/deal']);
  const hasDeal = computed(() => store.getters['account/hasDeal']);
  const checkedDomain = computed(() => store.getters['account/getCheckedDomain']);
  const selectedLocale = computed(() => store.getters['account/getSelectedLocale']);
  const dealStrings = computed(() => store.getters['account/getDealStrings']);
  const code = computed(() => store.state.account.redeem.code);
  
  // Methods for dispatching Vuex actions
  const createUser = async (payload) => await store.dispatch('account/createUser', payload);
  const setLoading = (loading) => store.commit('account/setLoading', loading);
  const getDeal = async (payload) => await store.dispatch('account/getDeal', payload);
  const clearDeal = () => store.dispatch('account/clearDeal');

  // Utility methods
  const stringForKey = (key) => getStringForKey(selectedLocale, key);
  const eulaLink = () => getLocalisedLinks(selectedLocale, linkType.eula);
  const privacyLink = () => getLocalisedLinks(selectedLocale, linkType.privacy);
  
  // Submission logic extracted from the component
  const submit = async (register, firebaseUser) => {
    if(firebaseUser) {
      register.name = firebaseUser.displayName;
      register.email = firebaseUser.email;
    }
   const link = await generateBranchLoginLink(register.name, register.email, originalLink)
   console.log('Link', link);
    const payload = {
      ...register,
      deal_id: deal?.id,
      offering: offering,
      code: code,
      referringLink: originalLink,
      locale: selectedLocale.key,
      loginDeepLink: link,
    };
    console.log('Payload', payload);
    await createUser(payload);
  };
 
   const  checkEmail = async (email) => {
     

    if (isEmail(email)) {
      let arr = email.split("@");
      let dom = arr[1];
      let reCheck = checkedDomain != null && dom != checkedDomain;

      //Clear out deal if domain changes
      if (reCheck) {
        clearDeal();
      }
      if (!deal || reCheck) {
        if (arr.length > 1) {
          gettingDeal.value = true;
          await getDeal({ domain: arr[1] });
          gettingDeal.value = false;
        }
      }
    }
  };
  return {
    account,
    createUser,
    setLoading,
    getDeal,
    clearDeal,
    stringForKey,
    submit,
    eulaLink,
    privacyLink,
    selectedLocale,
    dealStrings,
    checkEmail,
  };
}
