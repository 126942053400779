<template>
  <div v-if="!existingUser">
    <div style="height: 100px"></div>
    <partner-logo v-if="logo != null && !logoDisabled" :imgSrc="logo"></partner-logo>
    <deal></deal>
    <vces v-if="isVcesDeal && !isVcesGranted" class="mt-2 px-8" />
    <account v-if="!hideAccount" class="mt-2 px-8"></account>
  </div>
  <div
    v-else
    class="existing-account mx-auto d-flex flex-column text-center px-4"
    style="max-width: 450px"
  >
    <existing-user></existing-user>
  </div>
</template>

<style scoped>
.existing-account {
  margin-top: 120px;
}
</style>
<script>
import existingUser from "./existingUser";
import account from "./account";
import deal from "./deal";
import vces from "./vces";
import partnerLogo from "./partner-logo";
import { mapGetters } from "vuex";

export default {
  components: {
    existingUser,
    account,
    deal,
    partnerLogo,
    vces,
  },
  props: {
    logo: String,
    logoDisabled: Boolean,
    existingUser: Boolean,
    hideAccount: Boolean,
    isVcesDeal: Boolean,
    isVcesGranted: Boolean,
  },
};
</script>
