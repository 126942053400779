<template>
  <div>
    <div class="text--primary font-weight-bold text-h7">
      {{ stringForKey("createAnAccount") }}
    </div>
    <v-form ref="form" lazy-validation>
     <input
        v-model="register.name"
        id="full_name"
        class="cardElement mb-1 black--text"
        :placeholder="stringForKey('fullName')"
      />

      <input
        v-model="register.email"
        id="email"
        class="cardElement ma-0 black--text"
        :placeholder="stringForKey('emailAddress')"
        autocomplete="off"
      />

      <v-checkbox
      id="agreed_terms"
        class="ma-1 mt-2"
        v-model="register.agreed_terms"
        color="tertiaryGrey"
        light
        hide-details
      >
        <template v-slot:label>
          <div class="text-caption tertiaryGrey--text">
            <div>
              {{ stringForKey("terms") }}
              <a
                target="_blank"
                :href="eulaLink"
                @click.stop
                class="grey--text lighten-2"
              >
                {{ stringForKey("termsEula") }}
              </a>
              &amp;
              <a
                target="_blank"
                :href="privacyLink"
                @click.stop
                class="grey--text lighten-2 mr-1"
              >
                {{ stringForKey("termsPrivacy") }}
              </a>
            </div>
          </div>
        </template>
      </v-checkbox>
      <v-checkbox
      id="block_marketing"
        class="ma-1"
        v-model="register.block_marketing"
        color="tertiaryGrey"
        light
        hide-details
      >
        <template v-slot:label>
          <div class="text-caption tertiaryGrey--text">
            <div>
              {{ stringForKey("termsMarketing") }}
            </div>
          </div>
        </template>
      </v-checkbox>
      <v-checkbox
        class="ma-1"
        v-if="dataSharing"
        v-model="register.agreed_data_sharing"
        color="tertiaryGrey"
        light
        hide-details
      >
        <template v-slot:label>
          <div class="text-caption tertiaryGrey--text">
            <div v-if="dataSharing.handlebars">
              <component :is="dataSharingText"></component>
            </div>
            <div v-else>
              {{ dataSharing.copy }}
            </div>
          </div>
        </template>
      </v-checkbox>
      <v-alert
        :value="error != null"
        type="error"
        border="left"
        text
        dense
        class="ma-0 my-2 text-caption"
        style="max-width: 500px"
      >
        {{ error }}
      </v-alert>
      <v-btn
      id="submit_btn"
        color="primary"
        dark
        class="mt-6 white--text font-weight-bold text-caption"
        style="height: 40px; width: 100%"
        :block="isMobile"
        :loading="loading || gettingDeal"
        @click="submit"
      >
        <span>
          {{ hasDeal ? stringForKey("claim") : stringForKey("trial") }}
        </span>
      </v-btn>
    </v-form>
<!--  UNCOMMENT TO ACTIVATE SOCIAL AUTH AND MAGIC LINK  -->
<!--    <h2 class="text-center mt-4">-->
<!--      {{ stringForKey("or") }}-->
<!--    </h2>-->
<!--    <v-row class="mt-2">-->
<!--      <v-col cols="12" class="text-center">-->
<!--        <SocialLogin />-->
<!--         -->
<!--        <MagicLink -->
<!--        :register="register"-->
<!--         -->
<!--        />-->
<!--      </v-col>-->
<!--    </v-row>-->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import rules from "@/rules.js";
import isEmail from "validator/es/lib/isEmail";
import { getStringForKey, getDealStringForKey } from "@/translations/utilities";
import { getLocalisedLinks, linkType } from "@/translations/utilities";
import { generateBranchLoginLink} from "@/helpers/utilities";
import MagicLink from "@/components/magic-link.vue";
import SocialLogin from "@/components/social-login.vue";
import { service } from "@/service";

export default {
  components: {
    MagicLink,
    SocialLogin,
  },

  data() {
    return {
      rules: rules,
      error: null,
      gettingDeal: false,
      register: {
        name: null,
        email: null,
        agreed_terms: false,
        block_marketing: false,
        agreed_marketing: false,
        agreed_data_sharing: false,
      },
    };
  },
  watch: {
    "register.email": function (val) {
      if (val) {
        this.register.email = val.toLowerCase().trim();
        this.checkEmail();
      }
    },
    "register.name": function (val) {
      if (val) this.register.name = val.toUpperFirstLetter();
    },
    "account.selectedLocale": function (val) {
      this.error = this.validate();
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      offering: "account/offering",
      originalLink: "account/originalLink",
      deal: "account/deal",
      hasDeal: "account/hasDeal",
      checkedDomain: "account/getCheckedDomain",
      selectedLocale: "account/getSelectedLocale",
      dealStrings: "account/getDealStrings",
      registerState: "account/register",
      fromApp: "account/getFromApp",
    }),
    eulaLink() {
      return getLocalisedLinks(this.selectedLocale.key, linkType.eula);
    },
    privacyLink() {
      return getLocalisedLinks(this.selectedLocale.key, linkType.privacy);
    },
    hasParkRunToken() {
      return this.$route.path == "/parkrun" && this.$route.query.token != null;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    loading() {
      return this.account.loading;
    },
    code() {
      return this.account.redeem.code;
    },
    successfulCode() {
      return this.deal != null && this.code != null;
    },
    dataSharing() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "dataSharing"
      );
    },
    dataSharingText() {
      var t = `<div>${this.dataSharing.copy}</div>`;

      var matches = this.dataSharing.copy.match(/{{.*?}}/g);

      for (var m of matches) {
        var key = m.replace("{{", "").replace("}}", "");
        var handlebar = this.dataSharing.handlebars[key];

        var text = handlebar.text;
        var url = handlebar.url;
        if (text != null && url != null) {
          t = t.replace(
            m,
            `<a target="_blank" href="${url}" @click.stop class="mr-1 grey--text lighten-2">${text}</a>`
          );
        } else if (text != null) {
          t = t.replace(m, text);
        }
      }

      return {
        template: t,
      };
    },
    hasName() {
      if (this.register.name == null || this.register.name === "") return;
      var arr = this.register.name
        .split(" ")
        .filter((n) => n != null && n != "");
      return arr.length > 1;
    },
    canSubmit() {
      var hasData =
        this.hasName &&
        this.register.email != null &&
        this.register.email !== "" &&
        this.register.agreed_terms === true;

      if (!hasData) {
        return false;
      }
      if (!isEmail(this.register.email.trim())) {
        return false;
      }
      if (this.dataSharing && this.dataSharing.is_required) {
        if (!this.register.agreed_data_sharing) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      createUser: "account/createUser",
      setLoading: "account/setLoading",
      getDeal: "account/getDeal",
      clearDeal: "account/clearDeal",
      vcesNotifyRedeemed: "account/vcesNotifyRedeemed",
    }),
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
    async checkEmail() {
      let email = this.register.email;

      if (isEmail(email)) {
        let arr = email.split("@");
        let dom = arr[1];
        let reCheck = this.checkedDomain != null && dom !== this.checkedDomain;

        //Clear out deal if domain changes
        if (reCheck) {
          this.clearDeal();
        }
        if (!this.deal || reCheck) {
          if (arr.length > 1) {
            this.gettingDeal = true;
            await this.getDeal({ domain: arr[1] });
            this.gettingDeal = false;
          }
        }
      }
    },
    validate() {
      if (!this.hasName) {
        return getStringForKey(this.selectedLocale, "validationName");
      }
      if (this.register.email == null || this.register.email === "") {
        return getStringForKey(this.selectedLocale, "valivalidatonEmail");
      }

      if (!isEmail(this.register.email.trim())) {
        return getStringForKey(this.selectedLocale, "validationEmailError");
      }
      if (this.register.agreed_terms !== true) {
        return getStringForKey(this.selectedLocale, "validationTerms");
      }

      if (this.dataSharing && this.dataSharing.is_required) {
        if (!this.register.agreed_data_sharing) {
          return getStringForKey(this.selectedLocale, "validationDataSharing");
        }
      }

      return null;
    },
    redirectToApp() {
      console.log('Redirecting to app');
  
      const appLink = process.env.VUE_APP_FIREBASE_PROJECT_ID === "vpt-dev-6deec" 
        ? "https://download.withu.fit/deal_response"
        : "https://app.withutraining.com/deal_response";
      
      window.location.href = appLink;
    },
    async submit() {
      //  logic for desktop users
      if (this.loading || this.gettingDeal) {
        return;
      }
      if (this.$refs.form.validate()) {
        this.error = this.validate();
        if (this.error) return;

        let payload = {
          ...this.register,
        };
        if (this.deal) {
          payload.deal_id = this.deal.id;
        }
        if (this.offering) {
          payload.offering = this.offering;
        }
        if (this.code) {
          payload.code = this.code;
        }
        if (this.originalLink) {
          payload.referringLink = this.originalLink;
        }

        payload.locale = this.selectedLocale.key;

        payload.loginDeepLink = await generateBranchLoginLink(this.register.name, this.register.email, this.originalLink);
        
        await this.createUser(payload);
      }
    },
    async checkCustomActions() {
      // if vces granted then notify redeemed
      if (this.account.isVcesGranted) {
        console.log('Vces granted');
        await this.vcesNotifyRedeemed();
        console.log('Vces notified redeemed');
      } 

      // TODO - also account for other deals if required
    },
  },
  async beforeMount() {
    this.register = this.registerState;
    
    if(this.account.redeem.fromApp && this.isMobile) {
      // console.log('User is from app and is using mobile device');
      // console.log("REDIRECTING BACK TO APP WITH SUCCESS");

      const payload = {
        token: this.account.redeem.appFlowToken
      };
 
      service.post("/attachDealToUserWithToken", payload).then(
        // get user_id from response and set it in register
         async (response) => {
          console.log('Response from attachDealToUserWithToken: ', response);
          if (response) {
            console.log("Successfully attached deal to user with token");
            this.account.redeem.appFlowUserId = response.userId;
            console.log('User ID: ', this.account.redeem.appFlowUserId);
            await this.checkCustomActions();
            console.log('Redirecting to app');
            this.redirectToApp();
          } else {
            console.log("Error attaching deal to user with token");
            this.setLoading(false);
          }
        }); 
    } else {
      this.setLoading(false);
    }
  },
};
</script>
<style >
.v-text-field--outlined fieldset {
  border-radius: 0;
  border-collapse: collapse;
  border-color: black;
  border-style: solid;
  border-width: 2px !important;
}
</style>