<template>
  <div>
    <v-container>
      <div v-if="isDesktop">
        <v-row no-gutters class="align-center">
          <v-col>
            <div
              class="primary--text h5 font-weight-bold text-center mx-auto"
              style="white-space: pre-line"
            >
              {{ dealTitle }}
            </div>
            <div
              class="
                secondaryGrey--text
                body-1
                font-weight-bold
                text-center
                mx-auto
              "
            >
              {{ dealSubtitle }}
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row no-gutters class="align-center">
          <div
            class="
              deal-full-width
              primary--text
              text-h5
              font-weight-bold
              text-center
            "
            style="white-space: pre-line"
          >
            {{ dealTitle }}
          </div>
          <div
            :class="`${
              hasCustomTitle ? 'secondaryGrey--text' : 'primary--text'
            } deal-full-width text-h6 text-center mt-2`"
          >
            {{ dealSubtitle }}
          </div>
          <div v-if="!hasCustomTitle" class="deal-full-width">
            <div
              class="secondaryGrey--text text-h6 font-weight-bold text-center"
            >
              {{ description }}
            </div>
            <div class="deal-full-width primary--text text-h6 text-center">
              {{ partner }}
            </div>
          </div>
          <div class="mt-6 deal-full-width">
            <v-divider class="mx-4"></v-divider>
          </div>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getStringForKey, getDealStringForKey } from "@/translations/utilities";

export default {
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      freeTrial: "account/freeTrial",
      deal: "account/deal",
      selectedLocale: "account/getSelectedLocale",
      dealStrings: "account/getDealStrings",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    hasSubscription() {
      return this.deal && this.deal.subscription != null;
    },
    partner() {
      let partner = getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.deal.partner"
      );
      if (partner) {
        return `${getStringForKey(
          this.selectedLocale,
          "toWithU"
        )}, ${getStringForKey(this.selectedLocale, "through")} ${partner}!`;
      }
    },
    code() {
      return this.account.redeem.code;
    },
    description() {
      var str = "";
      if (this.freeTrial) {
        if (this.isDesktop) {
          if(this.selectedLocale.key == "en") {
            str = `${getStringForKey(this.selectedLocale, "qualified")} ${
            this.freeTrial
          } ${getStringForKey(
            this.selectedLocale,
            "freeSubscription"
          )} ${getStringForKey(this.selectedLocale, "toWithU")}!`;
          } else {
            str = `${getStringForKey(this.selectedLocale, "qualified")}  ${getStringForKey(
            this.selectedLocale,
            "freeSubscription"
          )} ${
            this.freeTrial
          } ${getStringForKey(this.selectedLocale, "toWithU")}!`;
          }
 
        } else {
          str = `${this.freeTrial} ${getStringForKey(
            this.selectedLocale,
            "freeSubscription"
          )}`;
        }
      }
      var subscription = this.hasSubscription ? this.deal.subscription : null;
      if (subscription && subscription.discount_percent) {
        if (str) {
          str += `\n${getStringForKey(this.selectedLocale, "then")}`;
        }
        str += `${subscription.discount_percent}% ${getStringForKey(
          this.selectedLocale,
          "offYour"
        )}`;
      }
      return str;
    },
    dealTitle() {
      if (this.isMobile) {
        return (
          getDealStringForKey(
            this.dealStrings,
            this.selectedLocale,
            "content.deal.title"
          ) || getStringForKey(this.selectedLocale, "congratulations")
        );
      }
      return (
        getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.deal.title"
        ) || this.description
      );
    },
    hasCustomTitle() {
      return this.dealTitle != getStringForKey(this.selectedLocale, "congratulations")
    },
    dealSubtitle() {
      let partner = getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.deal.partner"
      );
      if (this.isMobile) {
        return (
          getDealStringForKey(
            this.dealStrings,
            this.selectedLocale,
            "content.deal.subtitle"
          ) ||  `${getStringForKey(
            this.selectedLocale,
            "availableTo"
          )} ${getStringForKey(this.selectedLocale, "membersOnly")}${
            partner ? ` ${partner}` : ""
          }`
        );
      }



      let partnerString;

      if (this.selectedLocale.key == "en") {
        partnerString =
          getDealStringForKey(
            this.dealStrings,
            this.selectedLocale,
            "content.deal.subtitle"
          ) ||
          `${getStringForKey(this.selectedLocale, "availableTo")} ${
            partner ? partner : ""
          } ${getStringForKey(this.selectedLocale, "membersOnly")}`;
      } else if (this.selectedLocale.key == "es") {
        partnerString =
          getDealStringForKey(
            this.dealStrings,
            this.selectedLocale,
            "content.deal.subtitle"
          ) ||
          `${getStringForKey(
            this.selectedLocale,
            "availableTo"
          )} ${getStringForKey(this.selectedLocale, "membersOnly")}${
            partner ? ` ${partner}` : ""
          }.`;
      }

      return partnerString;
    },
  },
};
</script>

<style scoped>
.deal-full-width {
  width: 100%;
}
</style>


