export const stringsEn = {
  or: "or",
  adBlock:
    " Looks like you are trying to unlock a special offer but your Adblock is currently turned on. Please turn off your adblock and hit: Try again",
  tryAgain: "Try Again",
  rating: "#1 Audio Fitness App. Trusted Globally.",
  audioFitnessApp: "The Audio Fitness App",
  audioFitnessAppMobile: "The Audio\nFitness App",
  soundedSoGood: "Exercise never sounded so good.",
  onDemandWorkouts: "1000+ on-demand workouts",
  partnered: "Partnered with",
  partnership: "Partnership deal",
  toWithU: "to WithU",
  through: "through",
  unlocked: "Unlocked through",
  coaches: "World-renowned coaches",
  programmes: "Programmes for all abilities",
  anytime: "Workout anytime, anywhere",
  disciplines: "15 Different Disciplines",
  workoutsRange:
    "We have an extensive range of different workout categories for you to choose from, accessible at home, in the gym or on the move. Whenever you want.",
  congratulations: "Congratulations!",
  offer: "You've accessed an offer",
  qualified: "You've qualified for",
  freeSubscription: "free subscription",
  then: "Then",
  offYour: "off your subscription",
  subscribed: "You already have a WithU subscription!",
  offerCompare: "This is how the new offer compares:",
  currentPlan: "Current Plan",
  fullAccess: "Full access to WithU",
  newOffer: "New Offer",
  availableTo: "Available to",
  membersOnly: "members only.",
  createAnAccount: "Create an account",
  fullName: "Full Name*",
  emailAddress: "Email Address*",
  terms: "By signing up I agree to the WithU",
  termsEula: "EULA",
  termsPrivacy: "Privacy Policy",
  termsMarketing:
    "Tick here if you do not wish to hear from WithU by email with information about your fitness progress, milestone celebrations, new features, products and more to keep you motivated.",
  trial: "start 14 day free trial",
  freeTrial: "Start free trial",
  claim: "Claim your offer",
  seeWhatUsers: "See what our users think",
  testimonial1:
    "“I never found time to go to the gym but 20 minutes in your living room a few times a week is so achievable and I have already noticed results!”",
  testimonial2:
    "“I've tried loads of apps but this one is the one for me. They are clear, encouraging…they are fun and have a huge range of different workouts for all.”",
  testimonial3:
    "“My whole family has actually got involved in sessions and the programme we are following is perfectly pitched at our level. 5/5!”",
  testimonial4:
    "“I loved completing a running programme on here, much better compared to others I have tried.”",
  testimonial5:
    "“I feel that having the audio cues and video is brilliant. Feel like there's loads to explore in this app, I pay monthly, great value”",
  testimonial6:
    "“It's like being in a class. Easy to do wherever you are so no excuses!”",
  enterCode: "Enter code here",
  codeRedeemed: "Code redeemed",
  redeemCode: "Redeem code",
  redeem: "Redeem",
  enterCodeHere: "Enter code here",
  codeError:
    "Oops, we could not verify your code. Please check and try again or please\nreach out to our support by tapping on the chat icon",
  choose: "Choose:",
  monthly: "Monthly",
  annual: "Annual",
  yearly: "Yearly",
  subscription: "Subscription",
  subscriptionFreeTrial: "free trial",
  discount: "Discount",
  cancelAnytime: "Cancel anytime.",
  afterTrial: "After trial ends",
  reminder: "Get a reminder 7 days before your trial ends",
  noCommitment: "Cancel anytime, no commitment.",
  hi: "Hi",
  choosePlan: "choose your plan",
  heresOffer: "here's your offer",
  withUSubscription: "WithU Subscription",
  purchaseCancelled: "Purchase Cancelled",
  cancelledRetry: "Looks like your purchase was cancelled. Want to try again?",

  cardDetails: "Enter credit card details",
  creditCard: "Credit card",
  startMembership: "Start Your Membership",
  termsPaymentTrial:
    "We require your payment information to initiate the free trial, but you will not be charged until after the {{ freeTrial }} free trial period. We will remind you prior to charging your card and your WithU Membership will renew automatically after the trial period at {{ amount }} until you cancel.",
  termsPayment:
    "We require your payment information to initiate your account. We will remind you prior to charging your card and your WithU Membership will renew automatically at {{ amount }} until you cancel.",
  termsPaymentOneOff:
    "We require your payment information to initiate your account. You will be charged a one-time fee of {{ amount }} for a 6-month WithU Membership. There will be no automatic renewal after the 6-month period.",
  newOfferBenefit:
    "You will receive the benefits of the new offer as soon as your last payment period has ended.",
  completedNextSteps:
    "Download WithU from the App Store (iOS) or Play Store (Android) and login with the same email details used to redeem this offer.",
  confirmationEmail: "A confirmation email has been sent to",
  thanksFor: "Thanks for subscribing to WithU!",
  smsNumber:
    "For a link to download the app and get started, enter your phone number below.",
  smsSent: "SMS Sent",
  smsError: "Error sending SMS",
  smsMessage:
    "Thanks for joining WithU, let's get started. Hit the link below to download the app.",
  downloadApp: "Download the app then login",
  downloadAppMobile: "Download WithU",
  downloadAppMobileIos: "App Store (iOS)",
  downloadAppMobileAndroid: "Play Store (Android)",
  smsDelays:
    "We are experiencing delays when sending emails to: Sky, Yahoo, AOL and Rocket email addresses. Please wait 30 minutes for your email to come through befoore contacting support",
  updatedSubscription: "We've updated your existing subscription for you",
  upgradedAccount: "Thanks for upgrading your account, you are all set",
  thanksForJoining: "Thanks for joining WithU,",
  existingBest: "Your existing subscription is best!",
  clickLink:
    "Please click on the link below to download WithU and activate your subscription;\n{{ link }}\n\nClick Log In to get started.",
  validationName: "Please enter your full name",
  valivalidatonEmail: "Please enter your email address",
  validationEmailError: "Please provide a valid email address",
  validationTerms:
    "Please agree to our terms and conditions to create an account",
  validationDataSharing:
    "Please agree to the data sharing conditions to create an account",
  day: "day",
  days: "days",
  week: "week",
  weeks: "weeks",
  months: "month's",
  monthsPlural: "months",
  year: "year",
  years: "year's",
  lifetime: "Lifetime's",
  free: "free",
  alreadyHaveSub: "You already have a WithU subscription!",
  compareOffer: "This is how the new offer compares:",
  fewMoreThings:
    "we just need a few more things from\nyou to activate your offer",
  alreadyRegistered: "This email is already registered with a WithU account.",
  sentCode: "We've sent a verification code to",

  loginCodeError: "Error verifying code",
  verify: "Verify",
  cancel: "Cancel",
  downloadInstructions:"To download WithU, head to the App Store (iOS) or Play Store (Android) on your phone, and search for\n'WithU: Audio Fitness App'.",
  createAccountErrorTitle: "There was an issue creating your account",
  createAccountErrorSubtitle: "Please try again or contact your link provider",
  oops: "Oops!",
  cancelTitle: "It looks like you already have a paid subscription.",
  cancelAppStore:"Please cancel your App or Play Store payment to avoid being charged during the deal period.",
  cancelStripe:'To avoid being charged during the deal period, please drop us a message at <a href="mailto:support@withutraining.com">support@withutraining.com</a> and our team will help you with cancelling your Stripe payment.',
  vcesNotEligieable: "Card does not appear to be eligible",
  vcesUrlError: "Could not generate Url",
  vcesCidInvalid: "Looks like the card you entered is not eligible",
  enterCardDetails: "Enter full card number",
  intercomVisaMessage: "Entering my Visa card number does not work.",
  enterPhoneNumber: "Enter a phone number",
};
