import {mapState, mapGetters} from "vuex";
import deal from "./components/deal";
import price from "@/components/price";
import account from "./components/account";
import partnerLogo from "./components/partner-logo";
import redeemCode from "./components/code";
import vces from "./components/vces";
import existingUser from "./components/existingUser";
import partnerBar from "@/components/partner-bar";
import progressIndicator from "@/components/progress-indicator";
import partnerHeader from "./components/partner-header";
import {getDealStringForKey} from "@/translations/utilities";

export default {
  name: "Home",
  components: {
    deal,
    account,
    redeemCode,
    vces,
    partnerLogo,
    price,
    existingUser,
    partnerBar,
    progressIndicator,
    partnerHeader,
  },
  data() {
    return {
      error: false,
      hideAccount: false,
      enteringCode: false,
      canEnterCode: false,
    };
  },
  watch: {
    canRedeemCode(val) {
      if (!val) {
        this.enteringCode = false;
        this.hideAccount = false;
      }
    },
    isVcesGranted(value) {
      if (this.isVcesDeal) {
        this.hideAccount = !value;
      }
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      alert: (state) => state.alert,
    }),
    ...mapGetters({
      hasDeal: "account/hasDeal",
      freeTrial: "account/freeTrial",
      existingUser: "account/existingUser",
      canRedeemCode: "account/canRedeemCode",
      deal: "account/deal",
      isReferralLink: "account/isReferral",
      dealStrings: "account/getDealStrings",
      selectedLocale: "account/getSelectedLocale",
      isVcesDeal: "account/isVcesDeal",
      isVcesGranted: "account/isVcesGranted",
    }),
    hasPartnershipBranding() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.external_partner.enabled"
      );
    },
    themeColor() {
      if (!this.hasPartnershipBranding) {
        return null;
      }
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.external_partner.theme_color"
      );
    },
    mainImage() {
      if (this.hasPartnershipBranding && this.isRedeem) {
        let partnerImg = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.external_partner.background_image"
        );
        if (partnerImg) {
          return {
            src: partnerImg,
            lazySrc: null,
          };
        }
      }

      if (this.isMobile) {
        return {
          src: require("@/assets/mobile-header.jpg"),
          lazySrc: require("@/assets/mobile-header-lo.jpg"),
        };
      }

      return {
        src: require("@/assets/desktop-left-op.png"),
      };
    },
    initLoading() {
      return this.account.initLoading;
    },
    loading() {
      return this.account.loading;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isTablet() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isRedeem() {
      return this.$route.path === "/redeem";
    },
    hasLinkAndAdblock() {
      return this.account.redeem.adblock;
    },
    description() {
      return this.enteringCode
        ? "Enter your code to access WithU"
        : this.freeTrial
          ? `${this.freeTrial} free access to WithU`
          : "Get started";
    },
  },
  methods: {
    toggleEnteringCode(val) {
      this.enteringCode = val;
      if (this.hideAccount) {
        this.hideAccount = false;
      }
    },
    getStep() {
      if (this.isRedeem) {
        if (
          (this.canRedeemCode && this.canEnterCode) ||
          (this.isVcesDeal && !this.isVcesGranted)
        ) {
          return 1;
        }
        return 2;
      } else {
        return 1;
      }
    },
  },
  mounted() {
    this.$gtm.trackEvent({
      event: "RegistrationStart",
      category: "category",
      action: "action",
      label: "label",
      value: "value",
    });
  },
  beforeDestroy() {
    this.$gtm.trackEvent({
      event: "CodeRedemptionComplete",
      category: "category",
      action: "action",
      label: "label",
      value: "value",
    });
  },
  beforeMount() {
    if (this.canRedeemCode) {
      this.enteringCode = this.isRedeem;
      this.canEnterCode = this.isRedeem;
      this.hideAccount = this.enteringCode;
    } else if (this.isVcesDeal) {
      this.hideAccount = !this.isVcesGranted;
    }
  },
};
