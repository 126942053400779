export const stringsEs = {
  or: "o",
  rating: "# 1 App de Audio Fitness. Reconocido Mundialmente.",
  audioFitnessApp: "La App de Audio Fitness",
  audioFitnessAppMobile: "La App de\nAudio Fitness",
  soundedSoGood: "El ejercicio nunca sonó tan bien.",
  onDemandWorkouts: "1000 entrenamientos en demanda",
  partnered: "En colaboración con",
  partnership: "Acuerdo de asociación",
  toWithU: "en WithU",
  through: "via",
  unlocked: "Desbloquear para",
  coaches: "Instructores de renombre mundial",
  programmes: "Programas para todas las habilidades",
  anytime: "Entrena cuando sea y dónde sea",
  disciplines: "15 disciplinas diferentes",
  workoutsRange:
    "Puedes elegir de nuestra amplia variedad de estilos de entrenamiento, los cuales puedes realizar desde casa, en el gimnasio o dónde te encuentres, cuando quieras.",
  congratulations: "¡Felicitaciones!",
  offer: "Has obtenido una oferta",
  qualified: "¡Tienes derecho a recibir",
  freeSubscription: "una suscripción gratis",
  lifetime: "de por vida",
  then: "Luego",
  offYour: "descuento en tu suscripción",
  subscribed: "¡Ya tienes una suscripción con WithU!",
  offerCompare: "La nueva oferta te da nuevos beneficios:",
  currentPlan: "Plan Actual",
  fullAccess: "Acceso total a WithU",
  newOffer: "Nueva oferta ",
  availableTo: "Sólo disponible",
  membersOnly: "para socios",
  createAnAccount: "Crear una cuenta",
  fullName: "Nombre completo*",
  emailAddress: "Dirección de correo electrónico*",
  terms: "Al registrarme acepto el WithU",
  termsEula: "CLUF",
  termsPrivacy: "las políticas de privacidad",
  termsMarketing:
    "Selecciona esta casilla si no deseas recibir notificaciones por correo electrónico de WithU con información del progreso de tu condición física, logros, nuevas funciones, productos y más con el fin de mantenerte motivado.",
  trial: "Empieza tu prueba gratis de 14 días",
  freeTrial: "Empieza prueba gratis ",
  claim: "PIDE TU OFERTA",
  seeWhatUsers: "Lee lo que opinan nuestros usuarios",
  testimonial1:
    "“Nunca tenía tiempo para ir al gimnasio pero 20 minutos en la sala de tu casa, un par de veces a la semana es tan alcanzable y yo, ya veo los resultados”",
  testimonial2:
    "“He usado un montón de apps pero esta es la app para mí. Tienen entrenamientos claros, motivadores...son divertidos y tienen una gran variedad de rutinas para todos”",
  testimonial3:
    "“¡Mi familia entera se involucró en las sesiones y el programa que estamos siguiendo es perfecta para nuestro nivel. 5 de 5!”",
  testimonial4:
    "“Me encantó terminar el programa de correr de la app, es mucho mejor que cualquier otra que he usado”",
  testimonial5:
    "“Siento que es brillante tener las señales visuales y auditivas. Siento que hay un montón por descubrir de esta app, yo pago mensualmente, es una excelente relación calidad - precio”",
  testimonial6:
    "“Es como estar en una clase. Es facíl de hacer en el lugar que sea, asique no hay excusas!”",
  codeRedeemed: "Código canjeado",
  redeemCode: "Canjear código",
  redeem: "CANJEA",
  enterCodeHere: "Ingresa el código aquí",
  codeError:
    "Uy, lamentablemente no pudimos verificar tu código. Por favor\nrevisa e intenta nuevamente o contácta nuestro equipo de soporte pulsando el ícono del chat.",
  choose: "Elige:",
  monthly: "Mensualmente",
  annual: "Anualmente",
  annualy: "Annualy",
  yearly: "Anual",
  subscription: "Suscripción",
  subscriptionFreeTrial: "Prueba gratis",
  discount: "Descuento",
  cancelAnytime: "Cancela en cualquier momento",
  afterTrial: "Después de que termine la prueba gratis",
  reminder:
    "Recibe un recordatorio 7 días antes de que termine tu prueba gratis",
  noCommitment: "Cancela cuando quieras, sin compromiso.",
  hi: "Hola",
  choosePlan: "elige tu plan",
  heresOffer: "Aquí está tu oferta",
  withUSubscription: "Suscripción WithU",
  purchaseCancelled: "Compra cancelada",
  cancelledRetry:
    "Parece que tu compra fue cancelada. ¿Quieres intentar de nuevo?",
  tryAgain: "Intenta nuevamente",
  cardDetails: "Ingresa los datos de tu tarjeta de crédito",
  creditCard: "Tarjeta de crédito",
  startMembership: "Activa tu membresía",
  termsPaymentTrial:
    "Requerimos los datos de cobro para la prueba gratis pero no se cobrará hasta finalizar la prueba gratuita de {{ freeTrial }}. Te recordaremos antes de cobrar tu tarjeta y tu membresía de WithU se renovará automáticamente después del período de prueba, cobrando {{ amount }} (incluyendo IVA) hasta que canceles tu suscripción. ",
  termsPayment:
    "Requerimos tu información de pago para iniciar tu cuenta. Te recordaremos antes de cobrar tu tarjeta de crédito y tu membresía de WIthU se renovará automáticamente en (.cantidad)  hasta que canceles.",
  termsPaymentOneOff:
    "Necesitamos tu información de pago para activar tu cuenta. Se te cobrará una tarifa única de {{ amount }} por una membresía de WithU de 6 meses. No habrá renovación automática después del período de 6 meses.",
  newOfferBenefit:
    "Recibirás los nuevos beneficios una vez que el pago del úlitimo período haya finalizado.",
  completedNextSteps:
    "Ve a la App Store (iOS) o Play Store (Android) para descargar WithU, o si deseas una descarga directa para empezar lo antes posible, haz click en el siguiente botón.",
  confirmationEmail: "Un email de confirmación ha sido enviado a",
  thanksFor: "¡Gracias por suscribirte a WithU!",
  smsNumber:
    "Para conseguir el link que te permita descargar la app y empezar, introduce tu número de teléfono a continuación",
  smsSent: "SMS enviado",
  smsError: "Error al enviar SMS",
  smsMessage:
    "Thanks for joining WithU, let's get started. Hit the link below to download the app.",
  downloadApp: "Descarga la App, luego haga inicio de sesión",
  downloadAppMobile: "Descargate WithU",
  downloadAppMobileIos: "App Store (iOS)",
  downloadAppMobileAndroid: "Play Store (Android)",
  smsDelays:
    "Estamos teniendo atrasos al enviar correos electrónicos a las cuentas de: Sky, Yahoo, AOL, y Rocket. Por favor espera 30 minutos para que llegue tu correo antes de contactar el equipo de soporte.",
  updatedSubscription: "Hemos actualizado tu suscripción actual",
  upgradedAccount: "Gracias por actualizar tu cuenta, ya estás listo",
  thanksForJoining: "Gracias por unirte a WithU,",
  existingBest: "¡Tu suscripción actual es la mejor!",
  clickLink:
    "Por favor haz clic en el siguiente enlace para descargar WithU y activar tu suscripción;\n{{ link }}\n\nPara empezar haz clic en inicio de sesión.",
  validationName: "Por favor ingresa tu nombre completo",
  valivalidatonEmail: "Por favor ingresa tu correo electrónico",
  validationEmailError:
    "Por favor proporciona una dirección de correo electrónico válido",
  validationTerms:
    "Por favor acepta nuestros términos y condiciones para crear una cuenta.",
  validationDataSharing:
    "Por favor acepta las condiciones de intercambio de datos",
  free: "gratis",
  alreadyHaveSub: "¡Ya tienes una suscripción de WithU!",
  compareOffer: "Así se compara la nueva oferta:",
  fewMoreThings:
    "Solo necesitamos algunos datos más de ti\npara activar la oferta.",
  alreadyRegistered:
    "Este correo electrónico ya está registrado con una cuenta de WithU",
  sentCode: "Hemos enviado un código de verificación a",
  enterCode: "Ingresa el código de verificación",
  loginCodeError: "Hubo un error verificando el código",
  verify: "Verificar",
  cancel: "Cancelar",
  downloadInstructions:
    "Para descargarte WithU, dirígete a la App Store (iOS) o Play Store (Android) y busca por\n'WithU: Audio Fitness App'.",
  day: "dia",
  days: "dia",
  week: "Semana",
  weeks: "Semanas",
  months: "mes",
  monthsPlural: "meses",
  year: "año",
  years: "año",
  createAccountErrorTitle: "Hubo un problema al crear tu cuenta.",
  createAccountErrorSubtitle:
    "Por favor, intenta nuevamente y si el problema continúa contacta tu proveedor del enlace.",
  oops: "Oops!",
  cancelTitle: "Parece que ya tienes una suscripción pagada",
  cancelAppStore:
    "Por favor, cancela tu pago en la App Store o Play Store para evitar los cargos durante el período de prueba.",
  cancelStripe:
    'Para evitar los cargos durante el período de prueba, escríbenos a <a href="mailto:support@withutraining.com">support@withutraining.com</a> y nuestro equipo te ayudará a cancelar el pago por Stripe. ',
  vcesNotEligieable: "La tarjeta parece no ser elegible",
  vcesUrlError: "No se pudo generar la URL",
  vcesCidInvalid: "Parece que la tarjeta que ingresaste no es elegible",
  enterCardDetails: "Ingrese el número completo de la tarjeta",
  intercomVisaMessage: "Ingresar el número de tarjeta de mi Visa no funciona.",
  enterPhoneNumber: "Introduce un número de teléfono"
};
