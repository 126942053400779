<template>
  <div>
    <v-row no-gutters style="height: 100vh">
      <v-col
        cols="7"
        class="justify-center"
        :align-self="hasDeal ? 'center' : null"
      >
        <!-- NOT REDEEMED -->
        <div v-if="!hasDeal" style="height: 100%">
          <v-img
            eager
            
            class="mx-auto"
            :src="mainImage.src"
            min-height="100%"
            max-height="100vh"
            :eager="true"
          />
          <div
            v-if="!hasPartnershipBranding"
            class="floating-icon mx-auto"
            :class="logo != null ? 'rating rating-branded' : 'rating'"
          >
            <v-img
              class="mx-auto"
              src="@/assets/stars.png"
              contain
              :eager="true"
              :width="logo != null ? '100px' : '120px'"
            />
            <div class="mx-auto mt-2 font-weight-bold white--text text-caption">
              {{ stringForKey("rating") }}
            </div>
          </div>
          <v-img

            v-if="customRedemptionLogo != null && !hasPartnershipBranding && !logoDisabled"
            class="floating-icon"
            style="bottom: 100px"
            :src="customRedemptionLogo"
            max-height="100px"
            max-width="200px"
            :eager="true"
            contain
          />
        </div>
        <!-- REDEEMED -->
        <div v-else>
          <!-- <div v-if="offerPageLogo != null" style="margin-top: 100px"></div> -->
          <v-img
             
            class="mx-auto mt-16 pt-16"
            max-height="550px"
            :src= "
              selectedLocale.key == 'es'
                  ? require('@/assets/desktop-redeem-es.png')
                  : require('@/assets/desktop-redeem.png')
            "
            width="40vw"
            contain
            :eager="true"
          />
          <div v-if="offerPageLogo != null && !logoDisabled" class="mt-4">
            <div class="black--text text-h7 text-center font-weight-bold">
              {{ stringForKey("partnered") }}:
            </div>
            <v-img
              class="mx-auto mt-4"
              :src="offerPageLogo"
              max-height="120px"
              max-width="200px"
              contain
            />
          </div>
        </div>
      </v-col>
      <v-col cols="5" class="justify-center px-8" align-self="center">
        <!-- RIGHT HAND SIDE -->
        <div v-if="hasDeal" style="margin-top: 100px"></div>
        <progress-indicator
          class="mt-8"
          :steps="(isRedeem && !isVcesDeal) ? 4 : 3"
          :currentStep="getStep()"
          :theme-color="themeColor"
        ></progress-indicator>
        <div v-if="isRedeem && hasPartnershipBranding">
          <partner-header
            class="mt-8"
            :is-dark="false"
            :logo-url="hasDeal ? logo : customRedemptionLogo"
            :logo-disabled="logoDisabled"
            :theme-color="themeColor"
            :is-mobile="false"
          ></partner-header>
        </div>
        <!-- HOME/REDEEM -->
        <div v-if="!hasDeal && !existingUser">
          <div v-if="!hasPartnershipBranding">
            <div
              class="text-h5 primary--text font-weight-bold text-center mt-8"
            >
              {{ pageTitle }}
            </div>
            <div
              class="text-h6 secondaryGrey--text font-weight-bold text-center"
            >
              {{ pageSubtitle }}
            </div>
          </div>
          <div class="mt-12 mx-auto justify-center d-flex">
            <div v-for="(bullet, index) in bullets" :key="index">
              <v-img
                class="mx-auto"
                :src="require(`@/assets/${bullet.icon}.png`)"
                :lazy-src="require(`@/assets/${bullet.icon}-lo.png`)"
                max-width="40"
                min-width="40"
                contain
              />
              <div
                class="bullet-text caption mt-2"
                :style="themeColor != null ? `color: #${themeColor}` : null"
              >
                {{ bullet.text }}
              </div>
            </div>
          </div>
          <div v-if="hasPartnershipBranding">
            <div
              class="text-h5 primary--text font-weight-bold text-center mt-8"
            >
              {{ pageTitle }}
            </div>
            <div
              class="text-h6 secondaryGrey--text font-weight-bold text-center"
            >
              {{ pageSubtitle }}
            </div>
          </div>
        </div>
        <!-- CODE ENTRY -->
        <div
          v-if="canRedeemCode && canEnterCode"
          class="mx-auto my-12 px-8"
          style="max-width: 380px"
        >
          <redeem-code
            :enteringCode="enteringCode"
            v-on:toggle="toggleEnteringCode"
          />
        </div>

        <!-- ACCOUNT ENTRY -->
        <div
          v-if="!existingUser"
          class="mx-auto my-12"
          style="max-width: 380px"
        >
          <deal v-if="hasDeal"></deal>
          <vces v-if="isVcesDeal && !isVcesGranted" />
          <account v-if="!hideAccount" />
        </div>
        <div
          v-else
          class="d-flex flex-column text-center mt-16 mx-auto"
          style="max-width: 450px"
        >
          <existing-user />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getDealStringForKey, getStringForKey } from "@/translations/utilities";
import redeem from "../redeem";

export default {
  mixins: [redeem],
  computed: {
    bullets() {
      let b = [
        {
          icon: "bullet-touch",
          text: getStringForKey(this.selectedLocale, "onDemandWorkouts"),
        },
        {
          icon: "bullet-coach",
          text: getStringForKey(this.selectedLocale, "coaches"),
        },
        {
          icon: "bullet-programme",
          text: getStringForKey(this.selectedLocale, "programmes"),
        },
        {
          icon: "bullet-time",
          text: getStringForKey(this.selectedLocale, "anytime"),
        },
      ];
      return b;
    },
    pageTitle() {
      let pageKey;
      let dealText;
      if (this.hasDeal) {
        pageKey = "content.deal.title";
      }
      if (this.isRedeem) {
        pageKey = "content.redeem.title";
      }
      if (pageKey) {
        dealText = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          pageKey
        );
      }
      return dealText == undefined || dealText == null
        ? getStringForKey(this.selectedLocale, "audioFitnessApp")
        : dealText;
    },
    pageSubtitle() {
      let pageKey;
      let dealText;
      if (this.hasDeal) {
        pageKey = "content.deal.subtitle";
      }
      if (this.isRedeem) {
        pageKey = "content.redeem.subtitle";
      }
      if (pageKey) {
        dealText = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          pageKey
        );
      }
      return dealText == undefined || dealText == null
        ? getStringForKey(this.selectedLocale, "soundedSoGood")
        : dealText;
    },
    logo() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.logo"
      );
    },
    customRedemptionLogo() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.redeem.logo"
      ) || this.logo;
    },
    offerPageLogo() {
     return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.offer_page_logo"
      ) || this.logo;

    },
    logoDisabled() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.logo_disabled"
      );
    },
  },
  methods: {
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
  },
};
</script>

<style scoped>
.floating-icon {
  position: absolute;
  left: 30vw;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.rating {
  top: 75vh;
}

.rating-branded {
  top: 80px;
}

.bullet-row {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 80%;
}

.bullet-text {
  word-wrap: break-word;
  text-align: center;
  color: #707070;
  max-width: 100px;
}

 

</style>
